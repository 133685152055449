var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ui-list',{attrs:{"heading":_vm.heading,"headings":[_vm.$t('fields.name'), _vm.$tc('models.role'), _vm.$tc('misc.not-logged-in'), 'search'],"items":_vm.users,"sort":[
      { prop: 'firstName', type: false },
      //{ prop: 'role', prop2: 'name', type: 'level2' }
    ],"search_selectors":['firstName', 'lastName', 'email', 'role']},on:{"onFilter":_vm.onFilter},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('el-row',{staticClass:"bg-gray-100 border-b border-gray-300"},[_c('form',{staticClass:"px-8 pt-6"},[_c('div',{staticClass:"flex -mx-3"},[_c('div',{staticClass:"flex-auto px-3 mb-5 md:mb-0"},[_c('ui-form-field',{attrs:{"label":_vm.$tc('labels.user.company_admin')}},[_c('company-field',{staticClass:"w-full",model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1)],1),_c('div',{staticClass:"flex-auto px-3 mb-5 md:mb-0"},[_c('ui-form-field',{attrs:{"label":_vm.$tc('labels.user.building_admin')}},[_c('building-field',{staticClass:"w-full",model:{value:(_vm.building),callback:function ($$v) {_vm.building=$$v},expression:"building"}})],1)],1),_c('div',{staticClass:"flex-auto px-3 mb-5 md:mb-0"},[_c('ui-form-field',{attrs:{"label":_vm.$tc('models.user', 2)}},[_c('department-field',{staticClass:"w-full",model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1)],1),_c('div',{staticClass:"flex-initial pl-3 mb-5 md:mb-0 custom-margin"},[_c('ui-button',{attrs:{"type":"grey"},on:{"click":_vm.onFilter}},[_vm._v(" "+_vm._s(_vm.$tc('labels.filter'))+" ")])],1),_c('div',{staticClass:"flex-initial px-3 mb-5 md:mb-0 custom-margin"},[_c('ui-button',{attrs:{"type":"grey"},on:{"click":_vm.clearFilter}},[_vm._v(" Clear ")])],1)])])])]},proxy:true},{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":`${
            props.item.firstName
              ? props.item.firstName
              : _vm.$t('misc.not-registered')
          } ${props.item.lastName ? props.item.lastName : ''}`,"info":props.item.email}}),_c('ui-list-data',{attrs:{"type":"tag","text":_vm.$t(`roles.${props.item.role.name}`)}}),(props.item.firstName == null && props.item.lastName == null)?_c('ui-list-data',{attrs:{"type":"tag","text":_vm.$t('misc.not-logged-in')}}):_c('ui-list-data',{attrs:{"type":"tag"}}),_c('ui-list-data',{staticClass:"flex justify-end",staticStyle:{"padding":"31px 24px"},attrs:{"type":"tag"}},[_c('ui-button',{attrs:{"type":"grey","uppercase":""},on:{"click":function($event){return _vm.$router.push({
              name: 'users-edit',
              params: { id: props.item.id }
            })}}},[_vm._v(_vm._s(_vm.$t('actions.edit')))])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }